import React from "react";
import { Col, Container, Row } from "reactstrap";

export default function HastleComponent() {
  return (
    <Container className="pt-5" id="hastle">
      <Row>
        <Col md="8" className="text-center mb-5 mx-auto">
          <div className="text-center mt-5">
            <h1>
              Itaja
              <br /> is Coming!
            </h1>
          </div>
          <div className="hastle-right align-items-center d-flex">
            <div>
              <h3 className="small-header mt-3">
                Shop globally, pay locally – Seamlessly connect to international markets using your local currency.
              </h3>
              <p className="text-muted mt-4 sub-text">
                Itaja's borderless commerce is coming soon to your Apple App Store and Google Playstore
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="hastle-icons">
          <Row className="px-md-5 p-0">
            {[...Array(8)].map((e, i) => (
              <Col md="auto" key={i} className="my-2 mx-auto" xs="3">
                <img
                  alt=""
                  src={
                    require(`../../assets/images/svgs/brands/brand${i + 1}.svg`)
                      .default
                  }
                  className="img-fluid"
                />
              </Col>
            ))}
          </Row>
          <Row>
            {[...Array(9)].map((e, i) => (
              <Col md="auto" key={i} className="my-2 mx-auto" xs="3">
                <img
                  alt=""
                  src={
                    require(`../../assets/images/svgs/brands/brand${i + 9}.svg`)
                      .default
                  }
                  className="img-fluid"
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
