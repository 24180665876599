import React from "react";
import Footer from "../components/common/Footer";
import Hero from "./SubComponents/Hero";
import Faq from "./SubComponents/Faq";

export default function Landing() {
  const HowItWorks = [
    {
      title: "Start with Itaja",
      desc: "Sign up and start shopping, or use us as a guest. We are not fussy but we promise it would be worth your while sticking with us.",
    },
    {
      title: "Shop & Pay",
      desc: "Browse global stores, select items, and pay in your local currency. No more worries about currency conversions. For businesses, we are here to help with custome orders and more specialized purchases.",
    },
    {
      title: "Track & Enjoy",
      desc: "Track your order's progress and eagerly await its arrival. Once it's delivered, enjoy your international purchases hassle-free.",
    },
  ];

  return (
    <div className="landing">
      <Hero />
      <section className="how-it-works">
        {HowItWorks.map((kind, i) => (
          <div key={i} >
            <p className="title">
              <h3>{kind?.title}</h3>
            </p>
            <p className="description">{kind?.desc}</p>
          </div>
        ))}
      </section>
      <section className="bottom-divided">
        <Faq />
        <Footer />
      </section>
    </div>
  );
}
