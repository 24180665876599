import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import MainChimpComp from "./MainChimpComp";

function Footer({ toggle, ...props }) {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="8" className="text-white newsletter">
            <h1 style={{ fontSize: "45px", fontWeight: 800 }}>Watch this Space!</h1>
            <p className="mt-5">
              We'll be sure to let you know when we launch! In the meantime, how
              about you join our newsleteer? We promise not to spam you.
            </p>
          </Col>

          <MainChimpComp />
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
