import React, { useState, useEffect } from "react";
import Icofont from "react-icofont";

export default function Faq() {
  const [collapse, setCollapse] = useState(false);
  const [activeState, setActiveState] = useState("0");
  const toggle = (param) => {
    setCollapse(!collapse);
    setActiveState(param === activeState ? "" : param);
  };

  useEffect(() => {
    if (document.querySelector("details")) {
      const details = document.querySelectorAll("details");
      details.forEach((targetDetail) => {
        targetDetail.addEventListener("click", () => {
          details.forEach((detail) => {
            if (detail !== targetDetail) {
              detail.removeAttribute("open");
            }
          });
        });
      });
    }
  }, []);

  let questionsArr = [
    {
      question: "What is Itaja?",
      answer:
        "At Itaja, we bridge the gap between borders and currencies, allowing you to shop from your favorite global stores and pay for services overseas using your local currency. Pay using methods you know like bank transfer, debit cards, mobile money our use our virtual cards! No $100 limits, no complex conversion rates, no minimum spends!",
      state: "1",
    },
    {
      question: "Who is Itaja for?",
      answer:
        "Itaja is designed for individuals and businesses who are eager to explore the global marketplace and unlock the potential of international shopping and payment solutions. No matter if you're an individual looking to get your favourite international brand or a business looking for a streamlined procurement process for international goods, Itaja is your trusted companion, providing secure, reliable, and convenient access to cross-border shopping and payments.",
      state: "1",
    },
    {
      question:
        "Does this mean I can shop for my daughter in Canada from Nigeria?",
      answer:
        "Yes! And not just her! Itaja aims to provide its services globally. You can pay in naira and shop from anywhere in the world, delivering to anywhere in the world!",
      state: "1",
    },
    {
      question: "How do I trust my order in Itaja's hands?",
      answer:
        "We prioritize security and reliability with secure transactions, verified partners, and transparent processes. Plus, we work with best-in-class logistics partners for timely deliveries. Rest easy knowing your order is in safe hands with Itaja.",
      state: "1",
    },
    {
      question: "This is great! How do I start?",
      answer: `We're putting in maximum effort to bring Itaja to your fingertips as soon as possible! We can't wait for you to explore all the exciting things we have in store for you. In the meantime, why not subscribe to our newsletter? We'll keep you in the loop with our progress and make sure you're among the first to know about the latest updates. Stay tuned and get ready for an amazing Itaja experience!`,
      state: "1",
    },
  ];

  return (
    <div id="faq">
      <h3 className="mb-5">
        Some questions <br /> you might have
      </h3>

      <section>
        {questionsArr.map((item, i) => (
          <details className="question-card align-items-center my-3">
            <summary onClick={() => toggle(`${item.state}`)}>
              <h5>{item.question}</h5>
              <Icofont
                icon={`${
                  activeState === `${item.state}`
                    ? "minus-circle"
                    : "plus-circle"
                }`}
                className={`ml-auto text-muted`}
              />
            </summary>
            <section className="question-answer">
              <span>{item.answer}</span>
            </section>
          </details>
        ))}
      </section>
    </div>
  );
}
